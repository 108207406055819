.image-skeleton {
    background: linear-gradient(90deg, #2c2c2c 25%, #242424 50%, #2c2c2c 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite; /* Ajustez la durée et le type d'animation selon vos préférences */
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
