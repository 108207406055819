:root {
    --phoneWidth: 1024;
}

.App {
    height: 100svh;
    background-color: #242424;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

h1, h2, h3, span {
    all: unset;

    display: block;
    color: white;
}

h1, h2, h3 {
    font-weight: bold;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h1, h2, img {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}